'use strict';

window.console || (window.console = {
	log: function log() {}
});
var IBANEZ = window.IBANEZ || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = 'ibanez';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	IBANEZ = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.com|.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
			$switch.keydown(function (e) {
				if (e.key === 'Enter') {
					$(e.currentTarget).toggleClass(a);
					$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
					if ($(e.currentTarget).hasClass(a)) {
						$(e.currentTarget).next('.js-toggleBlock').find('[tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
					} else {
						$(e.currentTarget).next('.js-toggleBlock').find('[tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
					}
				}
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}

				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (IBANEZ.va.window.width < IBANEZ.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 104;
					}
					// 商品詳細ページのナビの動き
					if (hash.indexOf('#products_detail_') !== -1) {
						$('.products-detail-nav').removeClass(a);
					}
				} else {
					$('.header-menu').focus();
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (IBANEZ.va.window.width < IBANEZ.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 90;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menubtn = $('.js-header-menu-btn');
			var $menublock = $('.js-header-menu-block');
			var $searchBtn = $('.header-search');
			var $searchBlock = $('.js-header-search-block');
			var scrollTopNow = void 0;
			var flag = false;
			var shareFlag = false;
			var searchFlag = false;

			// pc・sp共通ハンバーガーメニュー、searchメニュー
			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};
			var closeMenu = function closeMenu() {
				$menubtn.removeClass(v);
				$menublock.removeClass(v);
				fixedOffContainer();
				flag = false;

				// pc
				// 1層目の表示消す
				$('.js-header-category-parent').removeClass(a);
				$('.js-header-category-child').removeClass(a);
				// 2層目の表示消す
				$('.header-menuBlock-childCategory li a').removeClass(a);
				$('.header-menuBlock-childCategory li').removeClass(a);
				$('.header-menuBlock-childCategory').removeClass(a);
				// 3層目の表示消す
				$('.header-menuBlock-right li').removeClass(a);
				$('.header-menuBlock-right').removeClass(v);
				$('.header-menuBlock-right').removeClass(a);
				// sp
				// 中身のtoggleリセット
				$('.js-toggle').removeClass(a);
				$('.js-toggleBlock').css('display', 'none');
			};
			var closeSearchMenu = function closeSearchMenu() {
				$searchBtn.removeClass(v);
				$searchBlock.removeClass(v);
				fixedOffContainer();
				searchFlag = false;
			};

			// MENUボタン開閉
			$menu.on('click', function () {
				if (!flag) {
					if (searchFlag) {
						closeSearchMenu();
					}
					fixedOnContainer();
					$menubtn.addClass(v);
					$menublock.addClass(v);
					flag = true;
				} else {
					closeMenu();
				}
			});
			$menu.keydown(function (e) {
				if (e.key === 'Enter') {
					if (!flag) {
						if (searchFlag) {
							closeSearchMenu();
						}
						fixedOnContainer();
						$menubtn.addClass(v);
						$menublock.addClass(v);

						$('.header-searchBlock').find('[tabindex="0"]').each(function (idx, ele) {
							// SEARCHブロック内は不活性
							$(ele).attr('tabindex', '-1');
						});
						if (IBANEZ.va.window.width > IBANEZ.va.device.sp) {
							// PC
							$('.header-menuBlock-content').find('.js-header-category-parent, .js-header-category-notab').each(function (idx, ele) {
								$(ele).attr('tabindex', '0');
							});
							$('.header-menuBlock-lists').eq(0).focus(); // メニュー内の最初の要素にフォーカス
						} else {
							// SP
							$('.header-menuBlock-sp').find('[tabindex="-1"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '0');
							});
							$('.header-menuBlock-sp .js-toggleBlock').find('[tabindex="0"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '-1');
							});
						}
						flag = true;
					} else {
						closeMenu();
						if (IBANEZ.va.window.width > IBANEZ.va.device.sp) {
							$('.header-menuBlock-content').find('[tabindex="0"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '-1');
							});
						} else {
							$('.header-menuBlock-sp').find('[tabindex="0"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '-1');
							});
						}
					}
				}
			});

			// SEARCHボタン開閉
			$searchBtn.on('click', function () {
				if (!searchFlag) {
					if (flag) {
						closeMenu();
					}
					fixedOnContainer();
					$searchBtn.addClass(v);
					$searchBlock.addClass(v);
					searchFlag = true;
				} else {
					closeSearchMenu();
				}
			});
			$searchBtn.keydown(function (e) {
				if (e.key === 'Enter') {
					if (!searchFlag) {
						if (flag) {
							closeMenu();
						}
						fixedOnContainer();
						$searchBtn.addClass(v);
						$searchBlock.addClass(v);
						$('.header-searchBlock').find('[tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
						$('.header-menuBlock-content').find('[tabindex="0"]').each(function (idx, ele) {
							// MENUブロック内は不活性
							$(ele).attr('tabindex', '-1');
						});
						$('.js-header-prodSearch-select').focus();
						searchFlag = true;
					} else {
						closeSearchMenu();
						$('.header-searchBlock').find('[tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
					}
				}
			});

			// メガメニュー（PC）
			if (IBANEZ.va.window.width > IBANEZ.va.device.sp) {
				var _changeTab = function _changeTab(parents, child) {
					parents.each(function (idx, ele) {
						$(ele).hover(function () {
							_changeTabDetail(parents, child, ele);
						});
						$(ele).on('focusin', function () {
							_changeTabDetail(parents, child, ele);
						});
					});
				};

				var _changeTabDetail = function _changeTabDetail(_parents, _child, _ele) {
					if (!$rightBlock.hasClass(v)) {
						$rightBlock.addClass(v);
					}

					var index = _parents.index(_ele);
					_parents.removeClass(a);
					_child.removeClass(a);

					$(_ele).addClass(a);
					_child.eq(index).addClass(a);

					$('.header-menuBlock-right').scrollTop($('.header-menuBlock-right')[0].scrollHeight);
				};

				var $childCategoryBlock = $('.header-menuBlock-childCategory');
				var $rightBlock = $('.header-menuBlock-right');

				// MENU 1層目選択時のタブ切り替え
				var $categoryParent = $('.js-header-category-parent');
				var $categorychild = $('.js-header-category-child');
				var $categorynotab = $('.js-header-category-notab');

				$categorynotab.each(function (idx, ele) {
					$(ele).hover(function () {
						toggleCategoryBlockNotab();
					});
					$(ele).keydown(function (e) {
						if (e.key === 'Enter') {
							toggleCategoryBlockNotab();
						}
					});
					function toggleCategoryBlockNotab() {
						// 背景部分
						if (!$childCategoryBlock.hasClass(a)) {
							$childCategoryBlock.addClass(a);
							$rightBlock.addClass(a);
						}
						// 1層目の表示消す
						$('.js-header-category-parent').removeClass(a);
						$('.js-header-category-child').removeClass(a);

						// 2層目の表示消す
						$('.header-menuBlock-childCategory li a').removeClass(a);

						// 3層目の表示消す
						$('.header-menuBlock-right').removeClass(v);
						$('.header-menuBlock-right li').removeClass(a);
					}
				});

				$categoryParent.each(function (idx, ele) {
					$(ele).hover(function () {
						toggleCategoryBlockParent();
					});
					$(ele).keydown(function (e) {
						if (e.key === 'Enter') {
							toggleCategoryBlockParent();
						}
					});
					function toggleCategoryBlockParent(params) {
						// 背景部分
						if (!$childCategoryBlock.hasClass(a)) {
							$childCategoryBlock.addClass(a);
							$rightBlock.addClass(a);
						}
						// 2層目の表示消す
						$('.header-menuBlock-childCategory li a').removeClass(a);
						// 3層目の表示消す
						$('.header-menuBlock-right').removeClass(v);
						$('.header-menuBlock-right li').removeClass(a);

						// 他のカテゴリー2層目を非アクティブ
						$categoryParent.removeClass(a);
						$categorychild.removeClass(a);
						$categorychild.find('.header-menuBlock-lists').each(function (idx2, ele2) {
							$(ele2).attr('tabindex', '-1');
						});

						// 該当カテゴリー2層目をアクティブ
						$(ele).addClass(a);
						$categorychild.eq(idx).addClass(a);
						$categorychild.eq(idx).find('.header-menuBlock-lists').each(function (idx2, ele2) {
							$(ele2).attr('tabindex', '0');
						});
					}
				});

				// 2層目選択時の背景切り替え
				for (var i = 0; i < 7; i++) {
					_changeTab($('.js-header-childCategory-parent0' + i), $('.js-header-childCategory-child0' + i));
				}
			}

			// SHAREボタン
			// 初期表示がPCなら選択可にしておく
			if (IBANEZ.va.window.width > IBANEZ.va.device.sp) {
				$('.header-content-right-wrap').find('[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
			}
			$('.header-content-right-sp').on('click', function () {
				if (!shareFlag) {
					$('.header-content-right-overlay').addClass(a);
					$('.header-content-right-wrap').addClass(a);
					shareFlag = true;
				} else {
					$('.header-content-right-overlay').removeClass(a);
					$('.header-content-right-wrap').removeClass(a);
					shareFlag = false;
				}
			});
			$('.header-content-right-sp').keydown(function (e) {
				if (e.key === 'Enter') {
					if (!shareFlag) {
						$('.header-content-right-overlay').addClass(a);
						$('.header-content-right-wrap').addClass(a);
						$('.header-content-right-wrap').find('[tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
						shareFlag = true;
					} else {
						$('.header-content-right-overlay').removeClass(a);
						$('.header-content-right-wrap').removeClass(a);
						$('.header-content-right-wrap').find('[tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
						shareFlag = false;
					}
				}
			});
			$('.header-content-right-overlay').on('click', function () {
				$('.header-content-right-overlay').removeClass(a);
				$('.header-content-right-wrap').removeClass(a);
				shareFlag = false;
			});
		},
		headerNav: function headerNav() {
			if ($('.idx-main').length) {
				var mainHeight = $('.idx-main-item').outerHeight();
				var headerNav = $('.header-nav');
				var idxHeader = $('.idx .header-content');

				headerNav.addClass(a);

				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var thisScroll = $this.scrollTop();

					if (thisScroll > mainHeight) {
						headerNav.removeClass(a);
						idxHeader.addClass('bgColor');
					} else {
						headerNav.addClass(a);
						idxHeader.removeClass('bgColor');
					}
				});
			}
		},
		CategoryList: function CategoryList() {
			if ($('.js-CategoryList').length) {
				var ProductCategoryListFlag = false;
				var $spSearch = $('.js-sp-search-block');
				$spSearch.find('select, input, a, [tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				var toggleSearch = function toggleSearch() {
					$spSearch.stop().slideToggle(200);
					if (!ProductCategoryListFlag) {
						$('.js-sp-search-btn .icon-arrow_down').addClass(a);
						$spSearch.find('select, input, a, [tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
						ProductCategoryListFlag = true;
					} else {
						$('.js-sp-search-btn .icon-arrow_down').removeClass(a);
						ProductCategoryListFlag = false;
					}
				};

				$('.js-sp-search-btn').on('click', function () {
					toggleSearch();
				});
				$('.js-sp-search-btn').keydown(function (e) {
					if (e.key === 'Enter') {
						toggleSearch();
					}
				});

				// 初期表示がSPなら閉じておく
				if (IBANEZ.va.window.width <= IBANEZ.va.device.sp) {
					toggleSearch();
				}
			}
		},
		CategoryListChange: function CategoryListChange() {
			if ($('.js-select-change').length) {

				// ページごとの初期のカテゴリ選択
				var selectpage = function selectpage(val, page) {
					if (pagePath === '/jp/' + page + '/category/' + val + '/list/') {
						selectCategory.val(val);
						$('.js-' + page + '_select-' + val).addClass(a);
					} else if (pagePath === '/jp/' + page + '/category/' + val + '/list/index.html') {
						selectCategory.val(val);
						$('.js-' + page + '_select-' + val).addClass(a);
					}
				};

				// カテゴリ変更時


				var pagePath = location.pathname;
				var selectCategory = $('.js-select-change');
				var pageType = '';

				if ($('.inc_common_products_search').length) {
					pageType = 'products';
				} else if ($('.inc_common_artists_search').length) {
					pageType = 'artists';
				}

				// ページごとの初期のカテゴリ選択
				selectpage('eg', pageType);
				selectpage('eb', pageType);
				selectpage('hb', pageType);
				selectpage('ag', pageType);
				selectpage('ele', pageType);
				selectpage('accessories', pageType);selectCategory.change(function (e) {
					var changeVal = $(e.currentTarget).val();
					cangepage('eg', pageType);
					cangepage('eb', pageType);
					cangepage('hb', pageType);
					cangepage('ag', pageType);
					cangepage('ele', pageType);
					cangepage('accessories', pageType);

					function cangepage(val, page) {
						if (changeVal === val) {
							window.location.href = '/jp/' + page + '/category/' + val + '/list/';
						}
					}
				});
			}
		},
		CategoryListAllClear: function CategoryListAllClear() {
			if ($('.js-categoryListClear').length) {
				$('.js-categoryListClear').on('click', function () {
					window.location.href = location.pathname;
				});
			}
		},
		modModal: function modModal() {
			if ($('.js-modal').length) {
				var scrollTopNow = void 0;
				var $modal = $('.js-modal');
				var $modalBtn = $('.js-modal-btn');
				var $modalOverlay = $('.js-modal-overlay');
				var $modalContent = $('.js-modal-content');

				var modalClose = function modalClose(_event) {
					$modal.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					_event.preventDefault();
				};
				var modalOpen = function modalOpen(_event) {
					var modalImg = void 0;
					if ($(_event.currentTarget).find('.js-modal-img').length) {
						modalImg = $(_event.currentTarget).find('.js-modal-img').attr('src');
					} else if ($(_event.currentTarget).next('img').length) {
						modalImg = $(_event.currentTarget).next('img').attr('src');
					}
					$modalContent.html('<img src="' + modalImg + '"><i class="icon icon-cloase" tabindex="0"></i>');

					$modal.addClass(a);
					scrollTopNow = $(window).scrollTop();
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					_event.preventDefault();

					$('.js-modal-content i').focus();
					$('.js-modal-content i').keydown(function (e) {
						if (e.key === 'Enter') {
							modalClose(e);
						}
					});
					$modalContent.on('click', $('.js-modal-content i'), function (e) {
						modalClose(e);
					});
					$modalOverlay.on('click', function (e) {
						modalClose(e);
					});
				};

				$modalBtn.on('click', function (e) {
					modalOpen(e);
				});
				$modalBtn.keydown(function (e) {
					if (e.key === 'Enter') {
						modalOpen(e);
					}
				});
			}
		},
		headerProdSearch: function headerProdSearch() {
			if ($('.mod-siteSearch-box_products').length) {
				var $searchBtn = $('.js-header-prodSearch');
				var $searchSelect = $('.js-header-prodSearch-select');
				var $searchInput = $('.js-header-prodSearch-input');

				$searchInput.change(function (e) {
					if (!$('.js-header-prodSearch-input').val()) {
						$searchBtn.attr('href', '' + $searchSelect.val());
					} else {
						$searchBtn.attr('href', $searchSelect.val() + '?cf_search_product_code=' + $(e.currentTarget).val());
					}
				});
				$searchSelect.change(function (e) {
					if (!$('.js-header-prodSearch-input').val()) {
						$searchBtn.attr('href', '' + $(e.currentTarget).val());
					} else {
						$searchBtn.attr('href', $(e.currentTarget).val() + '?cf_search_product_code=' + $searchInput.val());
					}
				});
			}
		},
		checkPageResize: function checkPageResize() {
			var timer = 0;
			window.onresize = function () {
				if (timer > 0) {
					clearTimeout(timer);
				}
				timer = setTimeout(function () {
					location.reload();
				}, 200);
			};
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.headerMenu();
			_this.headerNav();
			_this.CategoryList();
			// _this.CategoryListChange();
			// _this.CategoryListAllClear();
			_this.modModal();
			_this.easeScrollHash();
			_this.headerProdSearch();
			// _this.checkPageResize();
		}
	};

	$(function () {
		return IBANEZ.localDecision() ? IBANEZ.localLoading() : IBANEZ.loadDelayScript();
	});
})(window.jQuery);