'use strict';

(function ($, IBANEZ, cssua) {
	var a = 'is-active';

	var findLink = function findLink() {
		var $findLink = $('.js-findLink');
		if ($findLink.length) {
			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var thisScroll = $this.scrollTop();
				var headheight = $('.js-findLink-firstArea').height();
				if (thisScroll > headheight) {
					$findLink.addClass(a);
				} else {
					$findLink.removeClass(a);
				}
			});
		}
	};
	var detailPageNav = function detailPageNav() {
		if ($('.products-detail-nav').length) {
			var detailNav = $('.products-detail-nav');
			var detailNavBtn = $('.products-detail-nav-btn');

			if (!cssua.ua.mobile) {
				detailNav.hover(function () {
					$(this).addClass(a);
				}, function () {
					$(this).removeClass(a);
				});
			} else {
				detailNavBtn.on('click', function () {
					if (!detailNav.hasClass(a)) {
						detailNav.addClass(a);
					} else {
						detailNav.removeClass(a);
					}
				});
			}
		}
	};
	var changeTab = function changeTab() {
		if ($('.js-tab-parent').length) {
			// タブ切り替え
			var parent = $('.js-tab-parent');
			var child = $('.js-tab-child');

			child.eq(0).addClass(a);
			parent.eq(0).addClass(a);

			parent.each(function () {
				$(this).on('click', function () {
					var index = parent.index(this);
					child.removeClass(a);
					parent.removeClass(a);
					$(this).addClass(a);
					child.eq(index).addClass(a);
				});
				$(this).keydown(function (e) {
					if (e.key === 'Enter') {
						var index = parent.index(this);
						child.removeClass(a);
						parent.removeClass(a);
						$(this).addClass(a);
						child.eq(index).addClass(a);
					}
				});
			});
		}
	};
	var logoChangeAcoustic = function logoChangeAcoustic() {
		$('.header-content-logo').find('img').attr('src', '/images/logo_acoustic.png');
	};
	var setShareLinks = function setShareLinks() {
		$('.js-share-item').each(function (idx, ele) {
			var url = encodeURIComponent(document.URL);
			var title = encodeURIComponent(document.title);
			var $this = $(ele);
			var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

			$this.attr('href', href);
		});
	};

	// findLink();
	detailPageNav();
	changeTab();
	if ($('.mod-topicPath-li').eq(2).length !== 0) {
		if ($('.mod-topicPath-li').eq(2).find('a').attr('href').indexOf('acoustic_guitars') !== -1) {
			logoChangeAcoustic();
		}
	}
	setShareLinks();
})(window.jQuery, window.IBANEZ, window.cssua);